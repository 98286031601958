<template>
  <div class="container  my-24 my-lg-40">
    <section class="row">
      <article class="col-12">
        <v-card class="p-16 py-md-32 p-lg-32 ul ul_type_lines">
          <p>
            <b>Приветствуем, Вы приглашены к прохождению теста!</b>
          </p>

          <v-button link>
            <v-icon-svg
              class="mr-8 svg-fill-ui-main-1"
              view-box="0 0 12 12"
              title="svg-icon-plus"
              width=".75rem"
              height=".75rem"
            >
              <svg-icon-plus></svg-icon-plus>
            </v-icon-svg>

            <b v-clipboard="copyToClipboard">
              Ссылка на Ваш тест
            </b>
          </v-button>

          <hr class="my-24"/>

          <p>
            Если вы планируете сделать паузу во время прохождения — <strong>сохраните ссылку.</strong>
            По ней Вы вернетесь к своему последнему вопросу.
          </p>

          <v-divider class="my-24"/>

          <p>
            Для прохождения мы рекомендуем Вым перейти в полноэкранный режим браузера.
            Для этого нажмите F11 (или <strong>FN+F11</strong>) на клавиатуре.
          </p>

          <div class="row mt-40">
            <div class="col">
              <v-button>Продолжить</v-button>
            </div>
          </div>
        </v-card>
      </article>
    </section>

    <v-snackbar v-model="showSnackbar">
      Ссылка <a :href="eventUrl">{{ this.eventUrl }}</a>  скопирована в буфер обмена.
    </v-snackbar>
  </div>
</template>

<script>
import { Clipboard } from '@directives'
import { VButton, VCard, VIconSvg, VSnackbar } from '@components/base'
import SvgIconPlus from '@components/icons/SvgIconPlus'
import VDivider from '@components/base/VDivider/VDivider'

export default {
  name: 'SGreetings',

  directives: {
    Clipboard
  },

  components: {
    VDivider,
    SvgIconPlus,
    VButton,
    VCard,
    VIconSvg,
    VSnackbar
  },

  data () {
    return {
      showSnackbar: false,
      eventUrl: 'eelslap.com  '
    }
  },

  methods: {
    copyToClipboard () {
      this.showSnackbar = true
      return this.eventUrl
    }
  }
}
</script>
